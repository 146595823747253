<template>
  <v-row class="ma-4" dense align="center">
    <v-col
      cols="12"
      class="header sticky mb-2 pl-2 py-4"
      style="margin-left: -32px"
    >
      <span style="font-size: 24px; font-weight: 400"
        >{{ $t("doc3.header") }} {{ $t("non_im.(Student)") }}</span
      >
      <v-btn style="float: right" color="grey" dark @click="savedraft()">
        <v-icon class="mr-1">mdi-file-edit</v-icon>save draft</v-btn
      >
    </v-col>
    <v-col class="mb-16"></v-col>

    <v-card outlined width="100vw" class="pa-4">
      <v-stepper v-model="e1" style="no-wrapped">
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1" color="#fda1c0">
            {{ $t("doc2.p1") }}
          </v-stepper-step>
          <v-divider />
          <v-stepper-step :complete="e1 > 2" step="2" color="#fda1c0">
            {{ $t("doc2.p2") }}
          </v-stepper-step>
          <v-divider />
          <v-stepper-step :complete="e1 > 3" step="3" color="#fda1c0">
            {{ $t("doc2.p3") }}
          </v-stepper-step>
          <v-divider />
          <v-stepper-step :complete="e1 > 4" step="4" color="#fda1c0">
            {{ $t("doc2.p4") }}
          </v-stepper-step>
          <v-divider />
          <v-stepper-step :complete="e1 > 5" step="5" color="#fda1c0">
            {{ $t("doc2.p5") }}
          </v-stepper-step>
          <v-divider />
          <v-stepper-step :complete="e1 > 6" step="6" color="#fda1c0">
            {{ $t("doc2.p6") }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <!-- <v-card class="mb-12" color="grey lighten-1" height="200px"></v-card> -->
            <div class="header">
              <span>{{ $t("doc2.part1") }}</span>
            </div>
            <v-card-text>
              <v-row class="mt-4 pa-0 ma-0" justify="center">
                <v-col cols="6" md="1">
                  <span>{{ $t("non_im.title") }}</span>
                </v-col>
                <v-col cols="6" md="3">
                  <v-autocomplete
                    v-if="!EngLang"
                    v-model="form.title"
                    :items="itemsTitle"
                    item-text="textTH"
                    item-value="value"
                    solo
                    dense
                  ></v-autocomplete>
                  <v-autocomplete
                    v-if="EngLang"
                    v-model="form.title"
                    :items="itemsTitle"
                    item-text="textEN"
                    item-value="value"
                    solo
                    dense
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="4">
                  <v-row>
                    <v-col cols="6" md="4">
                      <span>{{ $t("non_im.givenname") }}</span>
                    </v-col>
                    <v-col cols="6" md="8">
                      <v-text-field
                        v-model="form.give_name"
                        solo
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" md="4">
                  <v-row>
                    <v-col cols="6" md="4">
                      <span>{{ $t("non_im.middlename") }}</span>
                    </v-col>
                    <v-col cols="6" md="8">
                      <v-text-field
                        v-model="form.middle_name"
                        solo
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" md="4">
                  <v-row>
                    <v-col cols="6" md="4">
                      <span>{{ $t("non_im.familyname") }}</span>
                    </v-col>
                    <v-col cols="6" md="8">
                      <v-text-field
                        v-model="form.family_name"
                        solo
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="4">
                  <v-row>
                    <v-col cols="6" md="4">
                      <span>{{ $t("non_im.nationality") }}</span>
                    </v-col>
                    <v-col cols="6" md="8">
                      <v-autocomplete
                        v-model="form.mNationalityId"
                        :label="EngLang === true ? 'Select' : 'เลือก'"
                        :items="nationalityItems"
                        :item-text="EngLang ? 'name_en' : 'name_th'"
                        item-value="id"
                        solo
                        dense
                      >
                        <template v-slot:selection="{ item }">
                          <div v-if="EngLang === true">
                            {{ item.name_en }}
                          </div>
                          <div v-if="EngLang === false">
                            {{ item.name_th }}
                          </div>
                        </template>
                        <template v-slot:item="{ item }">
                          <div v-if="EngLang === true">
                            {{ item.name_en }}
                          </div>
                          <div v-if="EngLang === false">
                            {{ item.name_th }}
                          </div>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" md="4">
                  <v-row>
                    <v-col cols="6" md="4">
                      <span>{{ $t("non_im.dateofbirth") }}</span>
                    </v-col>
                    <v-col cols="6" md="8">
                      <v-menu
                        v-model="date_of_birthMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="form.date_of_birth"
                            solo
                            dense
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="form.date_of_birth"
                          @input="date_of_birthMenu = false"
                        >
                          <!-- @change="firstDateChange" -->
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" md="4">
                  <v-row>
                    <v-col cols="6" md="3">
                      <span>{{ $t("non_im.email") }}</span>
                    </v-col>
                    <v-col cols="6" md="9">
                      <v-text-field
                        v-model="form.email"
                        solo
                        dense
                        :rules="rules.emailRules"
                        placeholder="example@gmail.com"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" md="4">
                  <v-row>
                    <v-col cols="6" md="5">
                      <span>{{ $t("doc2.passport") }}</span>
                    </v-col>
                    <v-col cols="6" md="7">
                      <v-text-field
                        v-model="form.passport_number"
                        solo
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="4">
                  <v-row> </v-row>
                </v-col>
              </v-row>
            </v-card-text>
            <v-row class="pa-4" justify="end">
              <v-btn align color="#ff78ac" dark @click="e1 = 2">
                Continue
              </v-btn>
            </v-row>
            <!-- <v-btn text> Cancel </v-btn> -->
          </v-stepper-content>
          <v-stepper-content step="2">
            <div class="header">
              <span>{{ $t("doc2.part2") }}</span>
            </div>
            <v-card-text>
              <v-row class="mt-4" align="center">
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="6" md="3">
                      <span>{{ $t("non_im.studentid") }}</span>
                    </v-col>
                    <v-col cols="6" md="9">
                      <v-text-field
                        v-model="form.student_id"
                        solo
                        dense
                        type="number"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="0" md="6"></v-col>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="6" md="3">
                      <span>{{ $t("non_im.stutype") }}</span>
                    </v-col>
                    <v-col cols="6" md="9">
                      <v-autocomplete
                        v-model="form.mStudentTypeId"
                        :items="itemsstudent_types"
                        :item-text="itemChangeLang"
                        item-value="id"
                        solo
                        dense
                        @change="getOneAffiliation()"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>

                <!-- <v-col cols="6" md="2">
                  <span>{{ $t("non_im.stuexchange") }}</span>
                </v-col>
                <v-col cols="6" md="4">
                  <v-autocomplete
                    v-model="form.mExchangeVisitingStudentId"
                    :items="itemsexchange_visiting_student"
                    :item-text="itemChangeLang"
                    item-value="id"
                    :disabled="
                      userData.user_type == 'ADMIN'
                        ? false
                        : userData.user_type == 'SUPERADMIN'
                        ? false
                        : true
                    "
                    solo
                    dense
                  ></v-autocomplete>
                </v-col> -->

                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="6" md="3">
                      <span>{{ $t("non_im.affilation") }}</span>
                    </v-col>
                    <v-col cols="6" md="9">
                      <v-autocomplete
                        v-model="form.mAffiliationFacultyId"
                        :items="getOneItemAffiliation"
                        :item-text="itemChangeLang"
                        item-value="id"
                        :disabled="!form.mStudentTypeId"
                        solo
                        dense
                        @change="
                          getOneLevelEducation(form.mAffiliationFacultyId)
                        "
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="6" md="3">
                      <span>{{ $t("non_im.level") }}</span>
                    </v-col>
                    <v-col cols="6" md="9">
                      <v-autocomplete
                        v-model="form.mLevelOfEducationId"
                        :items="itemslevel_Of_education"
                        :item-text="itemChangeLang"
                        item-value="id"
                        :disabled="!form.mAffiliationFacultyId"
                        solo
                        dense
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="6" md="3">
                      <span>{{ $t("non_im.department") }}</span>
                    </v-col>
                    <v-col cols="6" md="9">
                      <v-autocomplete
                        v-model="form.mDepartmentId"
                        :items="itemsdepartment"
                        :item-text="itemChangeLang"
                        item-value="id"
                        :disabled="!form.mLevelOfEducationId"
                        solo
                        dense
                        @change="autoEndingDate()"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12">
                      <span>{{ $t("non_im.stuperiod") }}</span>
                      &nbsp;
                      <span style="color: red">{{
                        $t("non_im.adminOnly")
                      }}</span>
                    </v-col>
                    <v-col cols="6" md="1">
                      <span>{{ $t("non_im.startperiod") }}</span>
                    </v-col>
                    <v-col cols="6" md="3">
                      <v-menu
                        v-model="edu_infor_startMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="form.edu_infor_start"
                            solo
                            dense
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :disabled="
                              userData.user_type == 'ADMIN'
                                ? false
                                : userData.user_type == 'SUPERADMIN'
                                ? false
                                : true
                            "
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="form.edu_infor_start"
                          @input="edu_infor_startMenu = false"
                          @change="autoEndingDate()"
                        >
                          <!-- @change="firstDateChange" -->
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="6" md="1">
                      <span>{{ $t("non_im.endperiod") }}</span>
                    </v-col>
                    <v-col cols="6" md="3">
                      <v-menu
                        v-model="edu_infor_endMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="form.edu_infor_end"
                            solo
                            dense
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            disabled
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="form.edu_infor_end"
                          @input="edu_infor_endMenu = false"
                        >
                          <!-- @change="firstDateChange" -->
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col
                      cols="6"
                      md="1"
                      v-if="
                        userData.user_type == 'ADMIN' ||
                        userData.user_type == 'SUPERADMIN'
                      "
                    >
                      <span>{{ $t("non_im.emailcc") }}</span>
                    </v-col>
                    <v-col
                      cols="6"
                      md="3"
                      v-if="
                        userData.user_type == 'ADMIN' ||
                        userData.user_type == 'SUPERADMIN'
                      "
                    >
                      <v-text-field
                        v-model="form.email_cc"
                        :rules="rules.emailRules"
                        placeholder="example@gmail.com"
                        solo
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="3" v-if="userData.user_type == 'STUDENT'"> </v-col>

                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" md="7">
                      <v-row>
                        <v-col cols="6" md="4">
                          <span>{{
                            $t("doc2.edu_infor_extend_study_period")
                          }}</span>
                        </v-col>
                        <v-col cols="6" md="8">
                          <v-text-field
                            v-model="form.edu_infor_extend_study_period"
                            solo
                            dense
                            :disabled="
                              userData.user_type == 'ADMIN'
                                ? false
                                : userData.user_type == 'SUPERADMIN'
                                ? false
                                : true
                            "
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="3">
                      <input
                        :disabled="
                          userData.user_type == 'ADMIN'
                            ? false
                            : userData.user_type == 'SUPERADMIN'
                            ? false
                            : true
                        "
                        type="file"
                        accept="image/jpg,/png,application/pdf,image/"
                        id="file"
                        ref="form.attachFile6"
                        v-on:change="handleFileUpload('form.attachFile6')"
                      />
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-btn
                        :disabled="
                          userData.user_type == 'ADMIN'
                            ? false
                            : userData.user_type == 'SUPERADMIN'
                            ? false
                            : true
                        "
                        :loading="isSelecting"
                        @click="submitFile('form.attachFile6')"
                      >
                        Upload</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  class="text-left"
                  v-if="form.attachFile6"
                >
                  <p
                    style="color: #4caf50; cursor: pointer"
                    @click="onClickFile(form.attachFile6)"
                  >
                    {{ $t("non_im.viewfile") }}
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
            <v-row class="pa-4" justify="end">
              <v-btn text @click="e1 = 1"> Back </v-btn>
              <v-btn color="#ff78ac" dark @click="e1 = 3"> Continue </v-btn>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="3">
            <div class="header">
              <span>{{ $t("doc2.part3") }}</span>
            </div>
            <v-card-text>
              <v-row class="mt-4" align="center">
                <v-col cols="6" md="2">
                  <span>{{ $t("doc2.visa_types") }}</span>
                </v-col>
                <v-col cols="6" md="10">
                  <v-autocomplete
                    v-model="form.mVisaTypeId"
                    :items="itemsvisa_types"
                    :item-text="EngLang ? 'name_en' : 'name_th'"
                    item-value="id"
                    solo
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" md="2">
                  <span>{{ $t("doc2.visa_expiry_date") }}</span>
                </v-col>
                <v-col cols="6" md="2">
                  <v-menu
                    v-model="visa_expiry_dateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.visa_expiry_date"
                        solo
                        dense
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.visa_expiry_date"
                      @input="visa_expiry_dateMenu = false"
                    >
                      <!-- @change="firstDateChange" -->
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" md="2">
                  <span>{{ $t("doc2.visa_extension_until") }}</span>
                  <br />
                  <span style="color: red">{{ $t("non_im.adminOnly") }}</span>
                </v-col>
                <v-col cols="6" md="2">
                  <v-menu
                    v-model="visa_extension_untilMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.visa_extension_until"
                        solo
                        dense
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :disabled="
                          userData.user_type == 'ADMIN'
                            ? false
                            : userData.user_type == 'SUPERADMIN'
                            ? false
                            : true
                        "
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.visa_extension_until"
                      @input="visa_extension_untilMenu = false"
                    >
                      <!-- @change="firstDateChange" -->
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-text>
            <v-row class="pa-4" justify="end">
              <v-btn text @click="e1 = 2"> Back </v-btn>
              <v-btn color="#ff78ac" dark @click="e1 = 4"> Continue </v-btn>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="4">
            <div class="header">
              <span>{{ $t("doc2.part4") }}</span>
            </div>
            <v-card-text>
              <v-row class="mt-4" align="center">
                <v-col cols="6" md="4">
                  <span>{{ $t("non_im.uploadpasspord") }}</span>
                  &nbsp;
                  <span class="font-weight-bold" style="color: red">***</span>
                </v-col>
                <v-col cols="6" md="8">
                  <input
                    type="file"
                    accept="image/jpg,/png,application/pdf,image/"
                    id="file"
                    ref="form.attachFile1"
                    v-on:change="handleFileUpload('form.attachFile1')"
                  />
                  <v-btn small @click="submitFile('form.attachFile1')">{{
                    $t("non_im.upload")
                  }}</v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  class="text-left"
                  v-if="form.attachFile1"
                >
                  <p
                    style="color: #4caf50; cursor: pointer"
                    @click="onClickFile(form.attachFile1)"
                  >
                    {{ $t("non_im.viewfile") }}
                  </p>
                </v-col>
                <v-col cols="6" md="4">
                  <span>{{ $t("doc2.latest_visa_stamps_page") }}</span>
                  &nbsp;
                  <span class="font-weight-bold" style="color: red">***</span>
                </v-col>
                <v-col cols="6" md="8">
                  <input
                    type="file"
                    accept="image/jpg,/png,application/pdf,image/"
                    id="file"
                    ref="form.attachFile2"
                    v-on:change="handleFileUpload('form.attachFile2')"
                  />
                  <v-btn small @click="submitFile('form.attachFile2')">{{
                    $t("non_im.upload")
                  }}</v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  class="text-left"
                  v-if="form.attachFile2"
                >
                  <p
                    style="color: #4caf50; cursor: pointer"
                    @click="onClickFile(form.attachFile2)"
                  >
                    {{ $t("non_im.viewfile") }}
                  </p>
                </v-col>
                <v-col cols="6" md="4">
                  <span>{{ $t("doc2.certificate") }}</span>
                  &nbsp;
                  <span class="font-weight-bold" style="color: red">***</span>
                </v-col>
                <v-col cols="6" md="8">
                  <input
                    type="file"
                    accept="image/jpg,/png,application/pdf,image/"
                    id="file"
                    ref="form.attachFile3"
                    v-on:change="handleFileUpload('form.attachFile3')"
                  />
                  <v-btn small @click="submitFile('form.attachFile3')">{{
                    $t("non_im.upload")
                  }}</v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  class="text-left"
                  v-if="form.attachFile3"
                >
                  <p
                    style="color: #4caf50; cursor: pointer"
                    @click="onClickFile(form.attachFile3)"
                  >
                    {{ $t("non_im.viewfile") }}
                  </p>
                </v-col>
                <v-col cols="6" md="4">
                  <span>{{ $t("doc2.cu_medical_insurance") }}</span>
                  &nbsp;
                  <span class="font-weight-bold" style="color: red">***</span>
                </v-col>
                <v-col cols="6" md="8">
                  <input
                    type="file"
                    accept="image/jpg,/png,application/pdf,image/"
                    id="file"
                    ref="form.attachFile4"
                    v-on:change="handleFileUpload('form.attachFile4')"
                  />
                  <v-btn small @click="submitFile('form.attachFile4')">{{
                    $t("non_im.upload")
                  }}</v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  class="text-left"
                  v-if="form.attachFile4"
                >
                  <p
                    style="color: #4caf50; cursor: pointer"
                    @click="onClickFile(form.attachFile4)"
                  >
                    {{ $t("non_im.viewfile") }}
                  </p>
                </v-col>
                <v-col cols="6" md="4">
                  <span>{{ $t("doc2.certificate_of_vaccination") }}</span>
                </v-col>
                <v-col cols="6" md="8">
                  <input
                    type="file"
                    accept="image/jpg,/png,application/pdf,image/"
                    id="file"
                    ref="form.attachFile5"
                    v-on:change="handleFileUpload('form.attachFile5')"
                  />
                  <v-btn small @click="submitFile('form.attachFile5')">{{
                    $t("non_im.upload")
                  }}</v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  class="text-left"
                  v-if="form.attachFile5"
                >
                  <p
                    style="color: #4caf50; cursor: pointer"
                    @click="onClickFile(form.attachFile5)"
                  >
                    {{ $t("non_im.viewfile") }}
                  </p>
                </v-col>
                <h3 class="ma-4 mt-9" style="color: red">*** = Required</h3>
              </v-row>
            </v-card-text>
            <v-row class="pa-4" justify="end">
              <v-btn text @click="e1 = 3"> Back </v-btn>
              <v-btn color="#ff78ac" dark @click="e1 = 5"> Continue </v-btn>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="5">
            <div class="header">
              <span>{{ $t("doc2.part5") }}</span>
            </div>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="12" class="text-center">
                  <span style="color: red">
                    {{ $t("non_im.adminOnly") }}
                  </span>
                </v-col>
              </v-row>
              <v-row class="mt-4" align="center">
                <v-col cols="12" md="6" class="mt-n5">
                  <v-row>
                    <v-col cols="12" md="2">
                      <span>{{ $t("non_im.affilation") }}</span>
                    </v-col>
                    <v-col cols="12" md="10">
                      <v-autocomplete
                        v-model="form.mAffiliationFacultyId"
                        :items="getOneItemAffiliation"
                        :item-text="itemChangeLang"
                        item-value="id"
                        solo
                        dense
                        :disabled="
                          userData.user_type == 'ADMIN'
                            ? false
                            : userData.user_type == 'SUPERADMIN'
                            ? false
                            : true
                        "
                        @change="getInfo(form.mAffiliationFacultyId)"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6" class="mt-n5">
                  <v-row>
                    <v-col cols="12" md="2">
                      <span>{{ $t("non_im.bookno") }}</span>
                    </v-col>
                    <v-col cols="12" md="10">
                      <v-text-field
                        v-model="form.auth_signa_doc_no"
                        solo
                        dense
                        :disabled="
                          userData.user_type == 'ADMIN'
                            ? false
                            : userData.user_type == 'SUPERADMIN'
                            ? false
                            : true
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" md="6" class="mt-n5">
                  <v-row>
                    <v-col cols="12" md="3">
                      <span>{{ $t("non_im.director") }}</span>
                    </v-col>
                    <v-col cols="12" md="9">
                      <v-select
                        v-model="form.auth_signa_link_dean_director"
                        :items="itemsChancellor"
                        item-value="id"
                        :item-text="EngLang ? 'name_en' : 'name_th'"
                        solo
                        dense
                        :disabled="
                          userData.user_type == 'ADMIN'
                            ? false
                            : userData.user_type == 'SUPERADMIN'
                            ? false
                            : true
                        "
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" md="6" class="mt-n5"> </v-col>
                <!-- <v-col cols="12" md="6" class="mt-n5">
                  <v-row>
                    <v-col cols="6" md="7">
                      <span>{{ $t("non_im.appointment_acting") }}</span>
                    </v-col>
                    <v-col cols="6" md="5" class="mt-n5">
                      <v-radio-group
                        v-model="form.appointment_acting"
                        row
                        solo
                        dense
                        :disabled="
                          userData.user_type == 'ADMIN'
                            ? false
                            : userData.user_type == 'SUPERADMIN'
                            ? false
                            : true
                        "
                      >
                        <v-radio :value="1" label="Yes"></v-radio>
                        <v-radio :value="0" label="No"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-col> -->
                <v-col cols="6" md="6" class="mt-n5"> </v-col>

                <!-- <v-col
                  cols="12"
                  md="12"
                  v-if="form.appointment_acting == '1' ? true : false"
                  class="mt-n5"
                >
                  <v-row>
                    <v-col cols="2" md="1">
                      <span>{{ $t("non_im.appointment_acting_name") }}</span>
                    </v-col>
                    <v-col cols="4" md="5">
                      <v-text-field
                        v-model="form.appointment_acting_name"
                        solo
                        dense
                        :disabled="
                          userData.user_type == 'ADMIN'
                            ? false
                            : userData.user_type == 'SUPERADMIN'
                            ? false
                            : true
                        "
                      ></v-text-field>
                    </v-col>

                    <v-col cols="2" md="1">
                      <span>{{ $t("non_im.position") }}</span>
                    </v-col>
                    <v-col cols="4" md="5">
                      <v-text-field
                        v-model="form.appointment_acting_position"
                        solo
                        dense
                        :disabled="
                          userData.user_type == 'ADMIN'
                            ? false
                            : userData.user_type == 'SUPERADMIN'
                            ? false
                            : true
                        "
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="2" md="1">
                      <span>{{ $t("non_im.for") }}</span>
                    </v-col>
                    <v-col cols="4" md="5">
                      <v-text-field
                        v-model="form.appointment_acting_for"
                        solo
                        dense
                        :disabled="
                          userData.user_type == 'ADMIN'
                            ? false
                            : userData.user_type == 'SUPERADMIN'
                            ? false
                            : true
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col> -->

                <!-- <v-col cols="12" md="10" class="mt-n5">
                  <v-row>
                    <v-col cols="12" md="5">
                      <span>{{ $t("non_im.internal_memo") }}</span>
                    </v-col>
                    <v-col cols="12" md="7">
                      <input
                        type="file"
                        accept="image/jpg,/png,application/pdf,image/"
                        id="file"
                        ref="form.internalMemoFile"
                        v-on:change="handleFileUpload('form.internalMemoFile')"
                      />
                      <v-btn
                        small
                        @click="submitFile('form.internalMemoFile')"
                        :disabled="
                          userData.user_type == 'ADMIN'
                            ? false
                            : userData.user_type == 'SUPERADMIN'
                            ? false
                            : true
                        "
                        >{{ $t("non_im.upload") }}</v-btn
                      >
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                      class="text-left"
                      v-if="form.internalMemoFile"
                    >
                      <p
                        style="color: #4caf50; cursor: pointer"
                        @click="onClickFile(form.internalMemoFile)"
                      >
                        {{ $t("non_im.viewfile") }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col> -->
              </v-row>
            </v-card-text>
            <v-row class="pa-4" justify="end">
              <v-btn text @click="e1 = 4"> Back </v-btn>
              <v-btn color="#ff78ac" dark @click="e1 = 6"> Continue </v-btn>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="6">
            <div class="header">
              <span>{{ $t("doc2.part6") }}</span>
            </div>
            <v-card-text>
              <v-row class="mt-4" align="center">
                <!-- <v-col cols="6" md="2">
                  <span>{{ $t("non_im.coopcontact") }}</span>
                </v-col>
                <v-col cols="6" md="10">
                  <v-text-field
                    v-model="form.coor_con_link"
                    :label="$t(`non_im.cooplink`)"
                    solo
                    dense
                  ></v-text-field>
                </v-col> -->
                <v-col>
                  <v-row>
                    <v-col cols="12" md="12" class="text-center mt-n5">
                      <span style="color: red">
                        {{ $t("non_im.adminOnly") }}
                      </span>
                    </v-col>
                    <v-col cols="6" md="1">
                      <span>{{ $t("non_im.coopname") }}</span>
                    </v-col>
                    <v-col cols="6" md="3">
                      <v-text-field
                        v-model="form.coor_con_full_name"
                        solo
                        dense
                        :disabled="
                          userData.user_type == 'ADMIN'
                            ? false
                            : userData.user_type == 'SUPERADMIN'
                            ? false
                            : true
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="1">
                      <span>{{ $t("non_im.cooptel") }}</span>
                    </v-col>
                    <v-col cols="6" md="3">
                      <v-text-field
                        v-model="form.coor_con_tel"
                        solo
                        dense
                        :disabled="
                          userData.user_type == 'ADMIN'
                            ? false
                            : userData.user_type == 'SUPERADMIN'
                            ? false
                            : true
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="1">
                      <span>{{ $t("non_im.coopemail") }}</span>
                    </v-col>
                    <v-col cols="6" md="3">
                      <v-text-field
                        v-model="form.coor_con_email"
                        solo
                        dense
                        :disabled="
                          userData.user_type == 'ADMIN'
                            ? false
                            : userData.user_type == 'SUPERADMIN'
                            ? false
                            : true
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
            <v-row class="pa-4" justify="end">
              <v-btn text @click="e1 = 5"> Back </v-btn>
              <v-btn color="#ff78ac" dark @click="checkFilesBeforeSubmit()">
                Submit
              </v-btn>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      selectedApprover: {},
      itemsChancellor: [],
      isSelecting: false,
      findInfo: [],
      getOneItemAffiliation: [],
      itemChangeLang: "",
      userData: [],
      EngLang: true,
      nationalityItems: [],
      itemsroyal_thai_embassy: [],
      itemsstudent_types: [],
      itemsexchange_visiting_student: [],
      itemsaffiliation_faculty: [],
      itemslevel_Of_education: [],
      itemsdepartment: [],
      itemsvisa_types: [],
      itemscovid19_vaccine: [],
      itemsTitle: [
        {
          textTH: "นาย",
          textEN: "Mr.",
          value: "Mr",
        },
        {
          textTH: "นางสาว",
          textEN: "Ms.",
          value: "Ms",
        },
        // {
        //   textTH: "นาง",
        //   textEN: "Mrs.",
        //   value: "Mrs",
        // },
      ],
      date_of_birthMenu: false,
      edu_infor_startMenu: false,
      edu_infor_endMenu: false,
      visa_expiry_dateMenu: false,
      visa_extension_untilMenu: false,

      form: {
        attachFile1: "",
        attachFile2: "",
        attachFile3: "",
        attachFile4: "",
        attachFile5: "",
        attachFile6: "",
        attachFile7: "",
        internalMemoFile: "",

        student_id: null,
        mRoyalThaiEmbassyId: null,
        title: "",
        give_name: "",
        middle_name: "",
        family_name: "",
        mNationalityId: null,
        date_of_birth: null,
        email: "",
        passport_number: "",
        boostershot: [],
        mStudentTypeId: null,
        mVisaTypeId: null,
        mLevelOfEducationId: null,
        mAffiliationFacultyId: null,
        mExchangeVisitingStudentId: null,
        mDepartmentId: null,
        edu_infor_start: null,
        edu_infor_end: null,
        email_cc: "",
        auth_signa_doc_no: "",
        auth_signa_link_dean_director: "",
        auth_signa_operation_full_name: "",
        auth_signa_position: "",
        auth_signa_for: "",
        auth_signa_file_upload: "",
        coor_con_link: "",
        coor_con_full_name: "",
        coor_con_tel: "",
        coor_con_email: "",
        email_cc: "",
        edu_infor_extend_study_period: null,
        visa_expiry_date: null,
        visa_extension_until: null,
        edu_infor_file_upload: "",

        appointment_acting: 0,
        appointment_acting_name: "",
        appointment_acting_position: "",
        appointment_acting_for: "",
      },
      bostername: "",
      bosterdate: "",
      e1: 1,
      rules: {
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        selectRules: [(val) => !!val || "This field is required"],
      },
    };
  },
  async created() {
    const lang = localStorage.getItem("lang");
    if (lang == "en") {
      this.EngLang = true;
      this.itemChangeLang = "name_en";
    } else if (lang == "th") {
      this.EngLang = false;
      this.itemChangeLang = "name_th";
    }

    await this.getEmbassy();
    await this.getStudentTypes();
    await this.getExchangeVisitingStudent();
    await this.getAffiliationFaculty();
    await this.getLevelOfEducation();
    await this.getDepartment();
    await this.getVisaTypes();
    await this.getAllNationality();
    await this.getOneAffiliation();
    this.userData = JSON.parse(
      Decode.decode(localStorage.getItem("userCUdata"))
    );
    console.log("thisuserData", this.userData);
  },
  mounted() {
    // const lang = localStorage.getItem("lang");
    // if (lang == "en") {
    //   this.EngLang = true;
    //   this.itemChangeLang = "name_en";
    // } else if (lang == "th") {
    //   this.EngLang = false;
    //   this.itemChangeLang = "name_th";
    // }
  },
  methods: {
    autoEndingDate() {
      if (this.form.edu_infor_start) {
        let testDate = new Date(this.form.edu_infor_start);
        let year = testDate.getFullYear();
        if (this.form.mDepartmentId) {
          let itemDepart = this.itemsdepartment.filter(
            (x) => x.id == this.form.mDepartmentId
          );
          let day = testDate.getDate();
          if (day < 10) {
            day = "0" + day;
          }
          let month = testDate.getMonth() + 1;
          let endingDateYear = parseInt(year + itemDepart[0].year_amt);

          this.form.edu_infor_end = endingDateYear + "-" + month + "-" + day;
        }
      }
    },
    checkFilesBeforeSubmit() {
      if (
        !this.form.attachFile1 ||
        !this.form.attachFile2 ||
        !this.form.attachFile3 ||
        // !this.form.attachFile4 ||
        !this.form.attachFile4
      ) {
        this.$swal.fire({
          icon: "error",
          text: this.$t("personal-doc1.checkFilePart3"),
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        this.submit();
      }
    },
    onClickFile(val) {
      window.open(val, "_blank");
    },
    async handleFileUpload(questionNo) {
      // this.data[questionNo] = this.$refs[questionNo].files[0];
      // console.log("file", this.data[questionNo]);
    },
    async submitFile(questionNo) {
      console.log("this.$refs[questionNo]", this.$refs[questionNo]);
      if (this.$refs[questionNo]) {
        if (this.$refs[questionNo].files[0]) {
          // this.loading = true;
          let formData = new FormData();
          formData.append("files", this.$refs[questionNo].files[0]);
          formData.append("filename", this.$refs[questionNo].files[0].name);
          formData.append("type", questionNo);
          // formData.append("userId", this.userId);
          console.log("formData", formData);
          const auth = {
            headers: {
              "Content-Type": "multipart/form-data",
              // Authorization: `Bearer` + " " + this.checkuser.token,
            },
          };
          console.log("auth", auth);
          this.uploaded = true;
          const response = await this.axios.post(
            `${process.env.VUE_APP_API}/files/formData`,
            formData,
            auth
          );
          if (questionNo == "form.attachFile1") {
            this.form.attachFile1 = response.data.data.path;
          }
          if (questionNo == "form.attachFile2") {
            this.form.attachFile2 = response.data.data.path;
          }
          if (questionNo == "form.attachFile3") {
            this.form.attachFile3 = response.data.data.path;
          }
          if (questionNo == "form.attachFile4") {
            this.form.attachFile4 = response.data.data.path;
          }
          if (questionNo == "form.attachFile5") {
            this.form.attachFile5 = response.data.data.path;
          }
          if (questionNo == "form.attachFile6") {
            this.form.attachFile6 = response.data.data.path;
          }
          if (questionNo == "form.attachFile7") {
            this.form.attachFile7 = response.data.data.path;
          }
          if (questionNo == "form.internalMemoFile") {
            this.form.internalMemoFile = response.data.data.path;
          }
          // if (questionNo == "form.") {
          //   this.pic4 = response.data.data.path;
          // }
          // console.log('this.pic1',this.pic1,'this.pic2',this.pic2)
          // .then(function () {
          console.log("SUCCESS!!");
          console.log("response", response);
          // this.uploaded = false;
        }
      }
    },
    async getOneAffiliation() {
      this.form.mAffiliationFacultyId = null;
      this.form.mLevelOfEducationId = null;
      this.form.mDepartmentId = null;
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/affiliation_faculty`
        // `${process.env.VUE_APP_API}/affiliation_faculty?mStudentTypeId=${mStudentTypeId}`
      );
      this.getOneItemAffiliation = response.data.data;
    },
    async getOneLevelEducation(mAffiliationFacultyId) {
      this.getInfo(mAffiliationFacultyId);
      this.itemslevel_Of_education = [];
      this.form.mLevelOfEducationId = null;
      this.form.mDepartmentId = null;
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/level_Of_education`
      );
      this.itemslevel_Of_education = response.data.data;
      this.getOneDepartment(mAffiliationFacultyId);
    },

    async getOneDepartment(mAffiliationFacultyId) {
      this.itemsdepartment = [];
      this.form.mDepartmentId = null;
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/department?mAffiliationFacultyId=${mAffiliationFacultyId}`
      );
      console.log("response department", response.data.data);
      this.itemsdepartment = response.data.data;
    },

    async getInfo(mAffiliationFacultyId) {
      this.findInfo = this.getOneItemAffiliation.find(
        (x) => x.id === parseInt(mAffiliationFacultyId)
      );

      this.itemsChancellor = [];
      this.findInfo.act_instead_agent_th || this.findInfo.act_instead_agent_en
        ? this.itemsChancellor.push({
            id: 1,
            name_th: this.findInfo.act_instead_agent_th,
            name_en: this.findInfo.act_instead_agent_en,
          })
        : "";

      this.findInfo.dean_director_th || this.findInfo.dean_director_en
        ? this.itemsChancellor.push({
            id: 2,
            name_th: this.findInfo.dean_director_th,
            name_en: this.findInfo.dean_director_en,
          })
        : "";
      this.form.auth_signa_link_dean_director = this.itemsChancellor[0].id;
      if (this.EngLang === true) {
        // this.form.auth_signa_link_dean_director =
        //   this.findInfo.dean_director_en;
        this.form.appointment_acting_name = this.findInfo.agent_en;
        this.form.appointment_acting_position = this.findInfo.position_agent_en;
        this.form.appointment_acting_for = this.findInfo.act_instead_agent_en;
      } else {
        // this.form.auth_signa_link_dean_director =
        //   this.findInfo.dean_director_th;
        this.form.appointment_acting_name = this.findInfo.agent_th;
        this.form.appointment_acting_name = this.findInfo.agent_th;
        this.form.appointment_acting_position = this.findInfo.position_agent_th;
        this.form.appointment_acting_for = this.findInfo.act_instead_agent_th;
      }
      if (this.findInfo.is_agent === true) {
        this.form.appointment_acting = 1;
      } else {
        this.form.appointment_acting = 0;
      }
    },

    async getAllNationality() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/nationality`
      );
      this.nationalityItems = response.data.data;
      console.log("nationalityItems", this.nationalityItems);
    },
    async getEmbassy() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/royal_thai_embassy`
      );
      console.log("response royal_thai_embassy", response.data.data);
      this.itemsroyal_thai_embassy = response.data.data;
    },
    async getStudentTypes() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/student_types`
      );
      console.log("response student_types", response.data.data);
      this.itemsstudent_types = response.data.data;
    },
    async getExchangeVisitingStudent() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/exchange_visiting_student`
      );
      console.log("response exchange_visiting_student", response.data.data);
      this.itemsexchange_visiting_student = response.data.data;
    },
    async getAffiliationFaculty() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/affiliation_faculty`
      );
      console.log("response affiliation_faculty", response.data.data);
      this.itemsaffiliation_faculty = response.data.data;
    },

    async getLevelOfEducation() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/level_Of_education`
      );
      console.log("response level_Of_education", response.data.data);
      this.itemslevel_Of_education = response.data.data;
    },
    async getDepartment() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/department`
      );
      console.log("response department", response.data.data);
      this.itemsdepartment = response.data.data;
    },
    async getVisaTypes() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/visa_type`
      );
      console.log("response visa_Type", response.data.data);
      // this.itemsvisa_types = response.data.data;

      let itemsvisa_types_312 = [];
      itemsvisa_types_312 = response.data.data;
      itemsvisa_types_312.forEach((element) => {
        console.log(element);
        if (element.name_en == "to study (Non-Immigrant ED)") {
          this.itemsvisa_types.push(element);
          console.log(element);
        }
      });
      console.log("visatype", this.itemsvisa_types);

      // let visitOnly = this.itemsvisa_types.filter(
      //   (x) => x.name_en == "to study (Non-Immigrant ED)"
      // );

      // this.form.mVisaTypeId = visitOnly[0].id;
    },
    async savedraft() {
      // if (this.$refs.createForm.validate(true)) {

      this.userData = JSON.parse(
        Decode.decode(localStorage.getItem("userCUdata"))
      );
      console.log("thisuserData", this.userData);
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      this.form.mCityId = this.selectedcity;
      this.form.mCountryId = this.selectedcountry;
      const data = {
        userId: this.userData.id,
        status: "SAVE DRAFT",
        ...this.form,
      };

      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/student_lssuing_visa`,
        data,
        // this.form,
        auth
      );
      console.log("createCate", response);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: this.$t("status.submitSavedraft"),
          showConfirmButton: false,
          timer: 1500,
        });
        this.$router.push(`Edit-Doc3?id=${response.data.data.id}`);
        // this.$router.push("ManageVoucher");
      } else {
        this.$swal.fire({
          icon: "error",
          text: this.$t("status.submitUnsuccess"),
          showConfirmButton: false,
          timer: 1500,
        });
      }
      // }
    },
    async submit() {
      // if (this.$refs.createForm.validate(true)) {
      this.$swal({
        html: this.$t("status.submitConfirm"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancel",
        confirmButtonText: "Confirm",
        showDenyButton: true,
        denyButtonColor: "grey",
        denyButtonText: `Save Draft`,
        // reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.userData = JSON.parse(
            Decode.decode(localStorage.getItem("userCUdata"))
          );
          console.log("this,form", this.form);
          const auth = {
            headers: {
              Authorization: `Bearer ${this.userData.token}`,
            },
          };
          const data = {
            ...this.form,
            userId: this.userData.id,
            status: this.$t("status.dataStatus"),
            ...this.form,
          };
          const response = await this.axios.post(
            `${process.env.VUE_APP_API}/student_lssuing_visa`,
            data,
            // this.form,
            auth
          );
          console.log("createCate", response);
          console.log(response.data.response_status);
          if (response.data.response_status == "SUCCESS") {
            await this.$swal.fire({
              icon: "success",
              text: this.$t("status.submitSuccess"),
              showConfirmButton: false,
              timer: 2000,
            });
            await this.$router.push("/Doc4");
          } else {
            this.$swal.fire({
              icon: "error",
              text: this.$t("status.submitUnsuccess"),
              showConfirmButton: false,
              timer: 1500,
            });
          }
          // }
        } else if (result.isDenied) {
          this.savedraft();
        }
      });
    },
  },
};
</script>
<style scoped>
.header {
  background-color: #de5c8e;
  padding: 10px;

  color: white;
  border-radius: 5px;
}
</style>